const dev = {
    url: {
        getEnvironment: 'http://localhost:7071/api/getEnvironment',
        getUsersApps: 'http://localhost:7071/api/getUsersApps',
        getQlikTutorials: 'http://localhost:7071/api/getQlikTutorials',
        getListFile: 'http://localhost:7071/api/getlistFiles',
        getFile: 'http://localhost:7071/api/getFile',
        getUsers: 'http://localhost:7071/api/getUsers',
        getUser: 'http://localhost:7071/api/getUser',
        postDeleteUser: 'http://localhost:7071/api/postDeleteUser',
        postFileUpload: 'http://localhost:7071/api/postfileUpload',
        getOrgInfo: 'http://localhost:7071/api/getorginfo',
        postLogo: 'http://localhost:7071/api/postLogo',
        getTemplates: 'http://localhost:7071/api/getTemplates',
        postNewUser: 'http://localhost:7071/api/postNewUser',
        postInviteUser: 'http://localhost:7071/api/postInviteUser'
    }
};

const prod = {
    url: {
        getEnvironment: '/api/getEnvironment',
        getUsersApps: '/api/getUsersApps',
        getQlikTutorials: '/api/getQlikTutorials',
        getListFile: '/api/getlistFiles',
        getFile: '/api/getFile',
        getUsers: '/api/getUsers',
        getUser: '/api/getUser',
        postDeleteUser: '/api/postDeleteUser',
        postFileUpload: '/api/postfileUpload',
        getOrgInfo: '/api/getorginfo',
        postLogo: '/api/postLogo',
        getTemplates: '/api/getTemplates',
        postNewUser: '/api/postNewUser',
        postInviteUser: '/api/postInviteUser'
    }
};

export const uriConfig = process.env.NODE_ENV === "development" ? dev : prod;