import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { uriConfig } from '../../data/uri';
import { useAuth0 } from '@auth0/auth0-react';
import PopSnackBar from "../PopSnackBar/PopSnackBar";


const OrgLogo = (props) => {
    const {authToken, orgLogo, logoReload, setLogoFunc, cssClass, base64,setOrganisationId, setOrganisationName, ...others } = props

    const { user } = useAuth0();
    const [ logoExt, setLogoExt ] = useState(null);

    const snackRef = useRef();

    const getOrgInfo = () =>{        
        const url = uriConfig.url.getOrgInfo
        const headers = {
            'auth-authorization': 'Bearer ' + authToken
        }
    
        const parameters = { 
            'user_sub' : user.sub            
        }
    
        axios.get(url, { headers: headers, params: parameters })
        .then(function (response) {
            // handle success       
            //console.log(response.data)
            setLogoFunc(response.data.logo)
            setOrganisationId(response.data.organisationid)
            setOrganisationName(response.data.name)
        }).catch(function (error) {
            // handle error
            console.log(error);
            snackRef.current.snackBarOpen({ 'message': error })
        });
    }

    const getFileExtension = (fileBase64) => {

        let fileHeader = new Map();
    
        //get the first 3 char of base64
        fileHeader.set("/9j", "jpeg")
        fileHeader.set("iVB", "png")
    
        let res = ""
    
        fileHeader.forEach((v, k) => {
            if (k == fileBase64.substr(0, 3)) {
                res = v
            }
        })
    
        //if file is not supported
        if (res == "") {
            res = "unknown file"
        }
    
        //return map value
        setLogoExt(res);
    }

    useEffect(() => {
        if(logoReload === 1){
            getOrgInfo();        
        }        
    },[logoReload]);

    useEffect(() => {
        if(orgLogo !== null && base64 === true) {
            getFileExtension(orgLogo);
        }
    },[orgLogo]);

    return (
        <>
            {orgLogo && base64 && <img src={"data:image/"+logoExt+";base64, "+orgLogo} alt="current company logo" className={cssClass} />}
            {orgLogo && !base64 && <img src={orgLogo} alt="current company logo" className={cssClass} />}       
            <PopSnackBar ref={snackRef} />
        </>
    );
}

export default OrgLogo;