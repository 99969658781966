import React, { useState, useRef } from "react";
import { TextField } from "@mui/material";


// ValidatedTextField.js
const ValidatedTextField = ({ label, validator, onChange }) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);

    const handleChange = e => {
        const newValue = e.target.value;
        const errorMessage = validator(newValue);
        setValue(newValue);
        setError(errorMessage);
        onChange(!errorMessage);
    };


    return (
        <TextField fullWidth required
            label={label}
            value={value}
            onChange={handleChange}
            error={!!error}
            helperText={error? error: " "}
            sx={{ marginBottom: '15px'}}
        />
    );
};

export default ValidatedTextField;