import React, { useRef, useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import QlikIcon from '../../assets/qlik-icon.png'
import SnowflakeIcon from '../../assets/snowflake-icon.png'
import JedoxIcon from '../../assets/jedox-icon.png'
import TCIcon from '../../assets/taholaconnect-icon.png'
import QalyptusIcon from '../../assets/qalyptus-icon.png'
import AndroidSwitch from "../AndroidSwitch/AndroidSwitch";

const UpdateUsersAccess = (props) => {
    const { onSwitchToggle, tickboxFunc, ...others } = props;
    const initialSwitchesState =     {
        "Novus-AI Analytics": false,
        "Novus-AI Reports": false,
        "TaholaConnect": false,
        "Novus-AI Data Warehouse": false,
        "Jedox": false
    }
    // State to manage multiple switch toggles
    const [expandedSwitches, setExpandedSwitches] = useState(initialSwitchesState);



    const apps = [
        { name: 'Novus-AI Reports', url: 'https://tahola.qalyptus.net/hub', imagepath: 'QalyptusIcon', imagealttext: 'Qalyptus Alt Text' },
        { name: 'TaholaConnect', url: 'https://taholaconnect.projectnovus.co.uk/', imagepath: 'TCIcon', imagealttext: 'TC alt text' },
        { name: 'Novus-AI Analytics', url: 'https://taholacloud.eu.qlikcloud.com/', imagepath: 'QlikIcon', imagealttext: 'Qlik Alt Text' },
        { name: 'Novus-AI Data Warehouse', url: 'https://sp65144.eu-west-2.aws.snowflakecomputing.com/', imagepath: 'SnowflakeIcon', imagealttext: 'Snowflake Alt Text' },
        { name: 'Jedox', url: 'https://www.jedox.com', imagepath: 'JedoxIcon', imagealttext: 'Jedox Alt Text' },
    ]

    const handleToggle = (name) => {
        setExpandedSwitches((prev) => {
            const updatedState = {
                ...prev,
                [name]: !prev[name],
            };

            if (onSwitchToggle) {
                onSwitchToggle(name, updatedState[name]);
            }

            return updatedState;
        });
    };


    const renderList = apps.map((dataObj, index) => {
        // Determine the icon based on imagepath
        const icon =
            dataObj.imagepath === 'QlikIcon'
                ? QlikIcon
                : dataObj.imagepath === 'SnowflakeIcon'
                    ? SnowflakeIcon
                    : dataObj.imagepath === 'JedoxIcon'
                        ? JedoxIcon
                        : dataObj.imagepath === 'TCIcon'
                            ? TCIcon
                            : dataObj.imagepath === 'QalyptusIcon'
                                ? QalyptusIcon
                                : null;

        return (
            <div key={index}>
                <FormGroup>
                    {/* <<FormControlLabel
                        control={
                            <Android12Switch
                                checked={expandedSwitches[dataObj.name] || false}
                                onChange={() => handleToggle(dataObj.name)}
                            />
                        }
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {icon && (
                                    <img
                                        src={icon}
                                        alt={dataObj.imagealttext}
                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                    />
                                )}
                                <a href={dataObj.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                    {dataObj.name}
                                </a>
                            </div>
                        }
                    />> */}
                    <AndroidSwitch
                        labelObj={dataObj}
                        icon={icon}
                        checked={expandedSwitches[dataObj.name] || false}
                        onChange={() => handleToggle(dataObj.name)} />
                </FormGroup>

                {/* Render additional switches if "Novus-AI Analytics" is toggled */}
                {dataObj.name === 'Novus-AI Analytics' && expandedSwitches[dataObj.name] && (
                    <div style={{ marginLeft: '40px' }}>
                        <FormGroup>
                            {/* <FormControlLabel control={<Android12Switch />} label="Admin" />
                            <FormControlLabel control={<Android12Switch />} label="Groups" /> */}
                        </FormGroup>
                    </div>
                )}
            </div>
        );
    });;

    useEffect(() => {
        // console.log(expandedSwitches)
        tickboxFunc(expandedSwitches)
    }, [expandedSwitches]);

    return (
        <Box>
            {renderList}
            {/* <AndroidSwitch labelObj={testObj} icon={JedoxIcon}/> */}
        </Box>
    );
}

export default UpdateUsersAccess;