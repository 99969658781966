import React, { useRef, useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import ValidatedTextField from "../ValidatedTextField/ValidatedTextField";
import UpdateUsersAccess from "../UpdateUsersAccess/UpdateUsersAccess";
import AndroidSwitch from "../AndroidSwitch/AndroidSwitch";
import FormHelperText from '@mui/material/FormHelperText';


// // validators.js
// const nameValidator = value => {
//     if (value.length < 3) return "Name must be at least 3 characters long";
//     if (value.length > 30) return "Name must be less than 20 characters long";
//     if (!/^[a-zA-Z ]+$/.test(value))
//         return "Name must contain only letters and spaces";
//     return false;
// };
// const emailValidator = value => {
//     if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value))
//         return "Invalid email address";
//     return false;
// };

// FormValidation.js
const NewUserInput = (props) => {
    const formValid = useRef({ name: false, email: false });
    const { updateStateFunc, organisationId, organisationName, tickboxFunc, setPortalAdminState, ...others } = props;
    const [tickBoxState, setTickBoxState] = useState();
    const [portalAdminChecked, setPortalAdminChecked] = useState(false)

    // console.log("TESING USER",organisationId, organisationName)
    // validators.js
    const nameValidator = value => {
        if (value.length < 3) {
            updateStateFunc("name", null);
            return "Name must be at least 3 characters long"
        }
        else if (value.length > 30) {
            updateStateFunc("name", null);
            return "Name must be less than 20 characters long"
        }
        else if (!/^[a-zA-Z ]+$/.test(value)) {
            updateStateFunc("name", null)
            return "Name must contain only letters and spaces"
        }
        else
            updateStateFunc("name", value)
        // return false;
    };

    const emailValidator = value => {
        if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value)) {
            updateStateFunc("email", null)
            return "Invalid email address"
        }
        else
            updateStateFunc("email", value)
        // return false;
    };

    const tickBox = (state) => {
        setTickBoxState(state)
    };
    const handlePortalAdminToggle = (event) => {
        setPortalAdminState("Portal Admin",event.target.checked)
        setPortalAdminChecked(event.target.checked)
    };

    useEffect(() => {
        tickboxFunc(tickBoxState)
        //    console.log("state tick trigger",tickBoxState)
    }, [tickBoxState]);

    return (
        <Box component="form" noValidate sx={{ margin: '10px' }}>
            <Box >
                <ValidatedTextField
                    label="Name"
                    validator={nameValidator}
                    onChange={isValid => (formValid.current.name = isValid)}
                />
            </Box>
            <Box>
                <ValidatedTextField
                    label="Email"
                    validator={emailValidator}
                    onChange={isValid => (formValid.current.email = isValid)}
                />
            </Box>
            <Box>
                <AndroidSwitch labelObj={{ name: 'Portal Admin', url: null, imagepath: '', imagealttext: '' }}
                                    checked={portalAdminChecked}
                                    onChange={handlePortalAdminToggle}
                                     />
                <FormHelperText>Portal admins can access the Management Console!</FormHelperText>
            </Box>
            <Box>
                <hr></hr>
                <UpdateUsersAccess tickboxFunc={tickBox} />
            </Box>
        </Box>
    );
}


export default NewUserInput;