import React, { useRef, useEffect, useState } from "react";
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NewDialog from "../NewDialog/NewDialog";
import { Button, Paper } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import NewUserInput from "../NewUserInput/NewUserInput";
import axios from "axios";
import { uriConfig } from '../../data/uri';
import Alerts from "../Alert/Alert";


const AddUserButton = (props) => {
    const { user, btnFunc, authToken, organisationId, organisationName, tickboxFunc, ...others } = props;
    const [caller, setCaller] = useState(null);
    const [inviteClicked, setInviteClicked] = useState(true);
    const snackRef = useRef();
    const dialogRef = useRef();
    const [newUserName, setNewUserName] = useState();
    const [newUserEmail, setNewUserEmail] = useState();
    const [inviteUserState, setInviteUserState] = useState(0);
    const [newUserMessage, setNewUserMessage] = useState(null);
    const [tickBoxState, setTickBoxState] = useState();
    const [portalAdminState, setPortalAdminState] = useState({"Portal Admin":'GENERAL'});

    const updatePortalAdminState = (key, value) => {
        setPortalAdminState(prevState => ({
            ...prevState,
            [key]: value == true ? 'ADMIN' : 'GENERAL' // Update dynamically
        }));
    };
    
    const openDialog = (config) => {
        dialogRef.current.handleOpen(config);
    }

    const updateDialog = (config) => {
        dialogRef.current.updateModal(config)
    }

    const updateUserObject = (key, value) => {
        if (key === "email") {
            setNewUserEmail(value)
        }
        if (key === "name") {
            setNewUserName(value)
        }
    }
    const tickBox = (state) => {
        setTickBoxState(state)
    };

    const inputDialogueComponent = <><NewUserInput tickboxFunc={tickBox} organisationId={organisationId} organisationName={organisationName} updateStateFunc={updateUserObject} setPortalAdminState={updatePortalAdminState} />{newUserMessage ? <Alerts alertSeverity={1} alertMessage={newUserMessage} /> : null} </>
    const resetFormData = () => { setNewUserEmail(null); setNewUserEmail(null); setInviteClicked(true); setPortalAdminState({"Portal Admin":'GENERAL'}); }
    const dialogConfig = {
        title: "Invite new user",
        components: inputDialogueComponent,
        cancelButton: 1,
        cancelButtonFunc: () => resetFormData(),
        buttons: {
            bottom: [
                {
                    label: <><SendIcon sx={{ marginRight: '10px' }} />Invite</>,
                    disable: inviteClicked,
                    onClick: () => inviteUser()
                }
            ]
        }
    };

    const inviteUserApiCall = (email, full_name) => {
        const url = uriConfig.url.postInviteUser;
        let data = {
            "invited_email": email,
            "invited_fullname": full_name,
            "organisation_name": organisationName,
            "organisation_id": organisationId,
            tickBoxState,
            portalAdminState
            // "Novus-AI Analytics": false,
            // "Novus-AI Reports": true,
            // "TaholaConnect": true,
            // "Novus-AI Data Warehouse": false,
            // "Jedox": false
        }
        axios.post(url, data, { headers: { 'auth-authorization': 'Bearer ' + authToken } })
            .then(function (response) {
                // handle success
                // fetchUsers()
                snackRef.current.snackBarOpen({ 'message': response.data })
                setNewUserMessage("User has been created, invite has been sent to " + email)
                // setDeleteClicked(false)
                resetFormData()
                updateDialog(dialogConfig)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                error.response.status == 409 ? setNewUserMessage("User " + email + " already exists") : setNewUserMessage(error);
                console.log(error);
            });
    };

    const inviteUser = () => {
        let increment = inviteUserState + 1
        // console.log("Triggeed: ", increment)
        setInviteUserState(increment)
        setInviteClicked(true)
    }

    useEffect(() => {
        if (newUserEmail && newUserName) {
            setInviteClicked(false)
        }
        else {
            setInviteClicked(true)
        }
    }, [newUserEmail, newUserName]);

    useEffect(() => {
        updateDialog(dialogConfig)
        // console.log(inviteClicked)
    }, [inviteClicked]);

    useEffect(() => {
        if (inviteUserState > 0) {
            inviteUserApiCall(newUserEmail, newUserName)
        }
        // console.log(inviteUserState)
    }, [inviteUserState]);

    useEffect(() => {
        console.log("state tick trigger", tickBoxState)
    }, [tickBoxState]);

    useEffect(() => {
        console.log("portal admin trigger", portalAdminState)
    }, [portalAdminState]);

    useEffect(() => {
        updateDialog(dialogConfig)
    },
        [newUserMessage])

    return (
        <>
            {/* <IconButton
                aria-label="delete user"
                onClick={() => openDialog(dialogConfig)}
                sx={{
                    "&.Mui-selected": {
                        backgroundColor: "#cfc6e3"
                    },
                    "&.Mui-focusVisible": {
                        backgroundColor: "#cfc6e3"
                    },
                    ":hover": {
                        backgroundColor: "#cfc6e3"
                    }
                }}>
                <DeleteIcon sx={{ color: '#523f7f' }} />
            </IconButton> */}
            <Button
                onClick={() => openDialog(dialogConfig)}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<PersonAddIcon />}
            >
                Invite user
            </Button>
            <PopSnackBar ref={snackRef} />
            <NewDialog ref={dialogRef} />
        </>
    );
}

export default AddUserButton;