import React, { useRef, useEffect, useState } from "react";
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import NewDialog from "../NewDialog/NewDialog";


const DeleteUserIcon = (props) => {
    const { user, btnFunc,...others } = props;
    const [caller, setCaller] = useState(null);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const snackRef = useRef();
    const dialogRef = useRef();

    const openDialog = (config) => {
        dialogRef.current.handleOpen(config);
    }

    const updateDialog = (config) => {
        dialogRef.current.updateModal(config)
    }

    const initiateDeleteUser =  () => {
        setCaller('deleteUser')
    }

    const dialogConfig = {
        title: "Are you sure?",
        components: "Are you sure you would like to delete this user?",
        cancelButton: 1,
        buttons: {
            bottom: [
                {
                    label: '❌ DELETE',
                    disable: deleteClicked,
                    onClick: initiateDeleteUser
                }
            ]
        }
    };

    useEffect(() => {
        if (caller === 'deleteUser') {
            setDeleteClicked(true)            
            btnFunc(user)
            setCaller(null)
        }
    }, [caller])

    useEffect(() => {        
        updateDialog(dialogConfig)
    }, [deleteClicked])

    return (
        <>
            <IconButton
                aria-label="delete user"
                onClick={() => openDialog(dialogConfig)}
                sx={{
                    "&.Mui-selected": {
                        backgroundColor: "#cfc6e3"
                    },
                    "&.Mui-focusVisible": {
                        backgroundColor: "#cfc6e3"
                    },
                    ":hover": {
                        backgroundColor: "#cfc6e3"
                    }
                }}>
                <DeleteIcon sx={{ color: '#523f7f' }} />
            </IconButton>
            <PopSnackBar ref={snackRef} />
            <NewDialog ref={dialogRef} />
        </>
    );
}

export default DeleteUserIcon;