import React, { useState, useEffect, useRef } from "react";
import TopNav from './components/TopNav/TopNav';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SideNav from "./components/SideNav/SideIconNav"
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HomePage from './components/HomePage/HomePage';
import ContactPage from "./components/ContactPage/ContactPage";
import MissingPage from "./components/MissingPage/MissingPage";
import HoldingPage from "./components/Holding/Holding";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import RegistrationPage from "./components/Registration/Registration";
import TemplatePage from "./components/TemplatePage/TemplatePage";
import ManagementConsole from "./components/ManagementConsole/ManagmentConsole";
import PortalSettings from "./components/Settings/Settings";
import FileManagement from "./components/FileManagement/FileManagement";
import PopSnackBar from "./components/PopSnackBar/PopSnackBar";
import Loader from "./components/Loader/Loader";
import Box from '@mui/material/Box';
import PoweredBy from "./components/PoweredBy/PoweredBy";
import FileUpload from "./components/FileUpload/FileUpload";
import UserManagement from "./components/UserManagement/UserManagement";
import OrgLogo from "./components/OrgLogo/OrgLogo";
import axios from "axios";
import { uriConfig } from './data/uri';


// Tahola theme:
const theme = createTheme({
    palette: {
        primary: {
            main: '#523f7f',
        },
        secondary: {
            main: '#f0f0f0',
        }
    }
});

function App(props) {
    const { loadingError } = props;
    const { isLoading, error, loginWithRedirect, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const [holdingGroup, setHoldingGroup] = useState([])
    const [adminGroup, setAdminGroup] = useState(false)
    const [emailVerified, setEmailVerified] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);
    const [cardData, setCardData] = useState([]);
    const [qlikTutorialData, setQlikTutorialData] = useState([]);
    const [token, setToken] = useState(0);
    const [cardDataStatus, setCardDataStatus] = useState(0);
    const [qlikTutorialDataStatus, setQlikTutorialDataStatus] = useState(0);
    const [logo, setLogo] = useState(null);
    const [logoReload, setLogoReload] = useState(0);
    const [sideNavDisplay, setSideNavDisplay] = useState('flex');
    const [organisationId, setOrganisationId] = useState(null)
    const [organisationName, setOrganisationName] = useState(null)

    const snackRef = useRef();

    const setOrgLogo = (logo = null, reload = false) => {
        setLogo(logo)
        setLogoReload(reload)
    }

    const menuSelection = (index) => {
        setSelectedMenuItem(index)
    }

    const updateCardReloadStatus = (cardJson) => {
        setCardData(cardJson)
        setCardDataStatus(1)
    }

    const updateQlikTutorialStatus = (data) => {
        setQlikTutorialData(data)
        setQlikTutorialDataStatus(1)
    }

    const getAccessToken = async () => {
        try {
            const token = await getAccessTokenSilently()
            setToken(token)
        } catch (e) {
            snackRef.current.snackBarOpen({ 'message': e })
        }
    }

    const fetchUser = () => {        
        const url = uriConfig.url.getUser;
        axios.get(url, { headers: { 'auth-authorization': 'Bearer ' + token } })
            .then(function (response) {
                // handle success
                if (response.data && response.data.type && response.data.type === 'ADMIN') {
                    setAdminGroup(true)
                }
                else {
                    setAdminGroup(false)
                }
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    };

    useEffect(() => {
        const path = window.location.pathname
        const pages = ['/home', '/template', '/contact', '/managementconsole']
        const indexNo = pages.findIndex(x => x == path)
        setSelectedMenuItem(indexNo)
        if (path != '/registration') {
            async function login() {
                if (!isLoading && !user) {
                    await loginWithRedirect();
                }
            }
            login();
        } 
    }, [isLoading]);

    useEffect(() => {
        if (isAuthenticated) {
            setHoldingGroup(user['https://qlik.com/groups'].includes('Holding Group'))
            setEmailVerified(user['email_verified'])
            getAccessToken()
            setLogoReload(1)
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (token !== 0) {
            fetchUser()
        }
    }, [token]);

    useEffect(() => {
        const path = window.location.pathname
        const pages = ['/home', '/template', '/contact', '/managementconsole']
        const indexNo = pages.findIndex(x => x == path)
        setSelectedMenuItem(indexNo)
        if (path === '/registration') {
            setSideNavDisplay('none')
        } else {
            setSideNavDisplay('flex')
        }
    });

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <TopNav
                    selectedMenuItem={selectedMenuItem}
                    selectedFunc={menuSelection}
                    adminGroup={adminGroup}
                    display={sideNavDisplay}
                />
                {isAuthenticated && token != 0 && <><OrgLogo base64={true} authToken={token} orgLogo={logo} logoReload={logoReload} setLogoFunc={setOrgLogo} setOrganisationId={setOrganisationId} setOrganisationName={setOrganisationName} cssClass="logo-watermark" /> <PoweredBy /></>}
                {!error && isLoading && (
                    <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 3 }}>
                        <Box style={{ height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {loadingError === 'loading' ? <Loader /> : loadingError != null ? String(loadingError) : <Loader />}
                        </Box>
                    </Box>
                )}
                {isAuthenticated &&
                    <SideNav
                        selectedMenuItem={selectedMenuItem}
                        selectedFunc={menuSelection}
                        adminGroup={adminGroup}
                        display={sideNavDisplay}
                    />
                }

                <Routes>
                    <Route path="/registration" element={<RegistrationPage />} />
                    <Route path="/error" element={<ErrorPage />} />
                    {isAuthenticated && (<Route exact path="/" element={<Navigate to="/home" />} />)}
                    {isAuthenticated && (<Route path="*" element={<MissingPage />} />)}
                    {isAuthenticated && (<Route path="/home" element={!emailVerified ? <HoldingPage emailVerified={emailVerified} /> : holdingGroup ? <HoldingPage emailVerified={emailVerified} /> : <HomePage
                        cardData={cardData}
                        cardReloadStatusFunc={updateCardReloadStatus}
                        cardDataStatus={cardDataStatus}
                        tokenCallerFunc={getAccessToken}
                        authToken={token}
                        qlikTutorialStatusFunc={updateQlikTutorialStatus}
                        qlikTutorialData={qlikTutorialData}
                        qlikTutorialDataStatus={qlikTutorialDataStatus}
                    />} />)}
                    
                    {isAuthenticated && (<Route path="/contact" element={!emailVerified ? <HoldingPage emailVerified={emailVerified} /> : holdingGroup ? <HoldingPage emailVerified={emailVerified} /> : <ContactPage />} />)}
                    {isAuthenticated && (<Route path="/template" element={!emailVerified ? <HoldingPage emailVerified={emailVerified} /> : holdingGroup ? <HoldingPage emailVerified={emailVerified} /> : <TemplatePage />} />)}
                    {isAuthenticated && (<Route path="/managementconsole" element={!emailVerified ? <HoldingPage emailVerified={emailVerified} /> : holdingGroup ? <HoldingPage emailVerified={emailVerified} /> : adminGroup ? <ManagementConsole authToken={token} /> : <MissingPage />} />)}
                    {isAuthenticated && (<Route path="/settings" element={!emailVerified ? <HoldingPage emailVerified={emailVerified} /> : holdingGroup ? <HoldingPage emailVerified={emailVerified} /> : adminGroup ? <PortalSettings authToken={token} orgLogo={logo} setLogoFunc={setOrgLogo} logoReload={logoReload} /> : <MissingPage />} />)}
                    {isAuthenticated && (<Route path="/files" element={!emailVerified ? <HoldingPage emailVerified={emailVerified} /> : holdingGroup ? <HoldingPage emailVerified={emailVerified} /> : adminGroup ? <FileManagement authToken={token} /> : <MissingPage />} />)}
                    {isAuthenticated && (<Route path="/fileupload" element={!emailVerified ? <HoldingPage emailVerified={emailVerified} /> : holdingGroup ? <HoldingPage emailVerified={emailVerified} /> : adminGroup ? <FileUpload authToken={token} /> : <MissingPage />} />)}
                    {isAuthenticated && (<Route path="/usermanagement" element={!emailVerified ? <HoldingPage emailVerified={emailVerified} /> : holdingGroup ? <HoldingPage emailVerified={emailVerified} /> : adminGroup ? <UserManagement authToken={token} organisationId={organisationId} organisationName={organisationName} /> : <MissingPage />} />)}
                    {/* <Route path="/signup" element={<Signup />} /> */}
                </Routes>

                <PopSnackBar ref={snackRef} />
            </ThemeProvider>
        </Router>
    );
}

export default App;